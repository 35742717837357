footer {
  padding-top: 2rem !important;
  margin-top: 4rem !important;
  background: linear-gradient(253.54deg, #ec2027 -36.47%, #214192 99.33%);
  color: rgba(255, 255, 255, 1) !important;

  .footerList {
    display: flex;
    padding: 1rem;
    justify-content: space-between;
    flex-wrap: wrap;
    ul {
      padding: 0;
    }
    li,
    a {
      list-style: none;
      font-family: Inter;
      font-size: 14px;
      font-weight: 400;
      line-height: 23.88px;
      text-align: left;
      text-decoration: none;
      color: rgb(214, 209, 209);
      margin: 0.8rem 0;
      cursor: pointer;
      &:hover {
        color: rgb(60, 204, 248);
      }
    }
  }

  .footerLogo {
    max-width: 200px;
    height: 68.16px;
  }

  .logoFooter {
    max-width: 300.2px;
  }
  p {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    margin-top: 1rem;
  }
  .appStores {
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    img {
      width: 45% !important;
    }
  }
  hr {
    box-sizing: border-box;
    background: #bcbcbc;
    height: 0.1rem;
    margin: 0 !important;
    padding: 0 !important;
  }
  .copyRight {
    display: flex;
    padding: 1rem 0;
    justify-content: space-between;
    align-items: center;

    .btn {
      &:hover {
        background: $primary;
        border: 1px solid $primary;
      }
      margin: 0 8px;
      border: 1px solid #fff;
      color: #fff;
      border-radius: 8px !important;
      font-size: 14px;
      padding: 0.2rem 0.5rem;
      -webkit-border-radius: 8px !important;
      -moz-border-radius: 8px !important;
      -ms-border-radius: 8px !important;
      -o-border-radius: 8px !important;
    }

    span {
      display: block;
      color: #bcbcbc;
      font-feature-settings: "clig" off, "liga" off;
      font-family: Inter;
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
}
